
import React from "react";
import SiteRouter from "./SiteModules/SiteRouter";

//import HomePage from "./Pages/HomePage";
function App() {
  return (
    
    <SiteRouter />
  );
}

export default App;
