import * as React from "react";
import Dialog  from "@mui/material/Modal";
import './CustomerPopup.css'
import CustomerComp from "../../Components/CustomerComp";
import { useSelector } from "react-redux";
 
export default function CustomerPopup({ open, handlePopupClose }) {
  const  handlePopupClosemiddile=()=> handlePopupClose();
 const loginid = useSelector((state) => state.login.loginid);
  return (


        <Dialog  open={open} onClose={() => handlePopupClose()} >
          <div className="popup-OuterContainer">
          <div className="popup">
            <CustomerComp myCallback={()=>handlePopupClosemiddile()} userid={loginid}/> 
          </div> 
        </div>
        </Dialog >
  );
}
