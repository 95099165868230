import React from "react";
import Login from "../Components/Login";
import { useDispatch } from "react-redux";
import { login } from "../StateManager/loginslice";
//import { format } from "date-fns";
export default function LoginPage() {
  // const logState = useSelector((state) => state.login.loginstate);
  // const loguser = useSelector((state) => state.login.logUsername);
  // const loginid = useSelector((state) => state.login.loginid);
  const dispatch = useDispatch();

  function UpdateLogStatus(userid, username) {
    const payload = {
      loginstate: true,
      logUsername: username,
      loginid: userid.toString(),
    };
    dispatch(login(payload));
  }
  return (
    <div>
      <Login logActions={UpdateLogStatus} />
    </div>
  );
}
