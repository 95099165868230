
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
  
const theme = responsiveFontSizes(createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color:'#000000',
          backgroundColor: "#ffffff"
          
        },
      }
    }
  },
  spacing: 4,
  typography: {
    fontFamily: [
      'Optima'
    ].join(','),
    h1: {
      fontSize: '5rem',
      fontFamily: 'Optima',
    },
    h2: {
      fontSize: '3.5rem',
      fontFamily: 'Optima',
      fontStyle: 'bold',
    },
    h3: {
      fontSize: '2.5rem',
      fontFamily: 'Optima',
    },
    h4:{
      fontSize: '1.5rem',
      fontFamily: 'Optima',
    },
    h5:{
      fontSize: '0.5rem',
      fontFamily: 'Optima',
    },
    h6:{
      fontSize: '0.2rem',
      fontFamily: 'Optima',
    },
  body1:{
    fontSize: '1.0rem',
    fontFamily: 'Optima',
  },
  body2:{
    fontSize: '1.0rem',
    fontFamily: 'Optima',
  },

  },
  palette: {
    background: {
      default: 'SmokeyWhite'//white
    },
    primary: {
      main: '#000000',//Pentone 19-3906 TCX
    },
    secondary: {
      main: '#48494c',//pink
    },
    error: {
      main: '#D72A2A',//red
    },
    warning: {
      main: '#FC7B09',//orange
    },
    info: {
      main: '#6B7D6A',//gray
    },
    success: {
      main: '#09FE00',//green
    },
    text: {
      primary: '#000000',//Pentone 19-3906 TCX
      secondary: '#00000',//white
    },
  },
}));
  
  
export default theme;