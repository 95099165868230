
import { createSlice } from '@reduxjs/toolkit'

export const LoginSlice = createSlice({
  name: 'login',
  initialState: {
    loginstate: false,
    loginid:'',
    logUsername:'',
    logtime:0,
  },
  reducers: {
    logout: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.loginstate= false;
      state.logUsername='';
      state.loginid=''
      state.logtime=0;
    },
    login: (state,action) => {

      state.loginstate= true;
      state.logUsername=action.payload.logUsername;
      state.logtime=action.payload.logtime;
      state.loginid=action.payload.loginid;
    },
    
  },
})

export const { login,logout } = LoginSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectloginfo = (state) => state.login

export default LoginSlice.reducer

