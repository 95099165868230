import React, { Component } from "react";
import "./login.css";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";

import Button from "@mui/material/Button";
import logo from "./../Images/KalthLivingLogo.png";
import { GetDataFromService } from "../SiteModules/Connectivity";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { username: "", password: "", helpererror: "" };
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(itemname, itemvalue) {
    this.setState({
      [itemname]: itemvalue,
    });
   // console.log(this.state);
  }
  async ProcessLogin(event) {
    event.preventDefault();
    if (this.state.username === "") {
      this.changeValue("helpererror", 'Username Cannot be Empty');
    } 
    else if (this.state.username === "") {
      this.changeValue("helpererror", 'Password Cannot be Empty');
    } 
    else {
      const payload = { user: this.state.username, pass: this.state.password };
      const t = await GetDataFromService("/doLogin", "POST", payload);
      const { Data } = await t;
      //console.log(Data);
      if (Data?.error !== "") {
        this.changeValue("helpererror", Data.error);
      } else {
        this.changeValue("helpererror", "");
      }
     // console.log(Data[0]?.UID);
      if (Data[0]?.UID !== undefined) {
        this.props.logActions(Data[0]?.UID, Data[0]?.Username);
      }
    }
  }
  render() {
    return (
      <div className="mainDiv">
        <Box className="component" boxShadow={5} variant="div">
          <img src={logo} alt="imag" width={200} />

          <TextField
            onChange={(event) =>
              this.changeValue("username", event.target.value)
            }
            label="Username"
            fullWidth
            size="small"
            defaultValue=""
          />
          <div className="spacer" />
          <TextField
            size="small"
            label="Password"
            type="password"
            onChange={(event) =>
              this.changeValue("password", event.target.value)
            }
            helperText={this.state.helpererror}
            fullWidth
          />

          <div className="buttondiv">
            <Button
              variant="contained"
              onClick={(event) => this.ProcessLogin(event)}
            >
              Login
            </Button>
            <Button variant="contained"> Cancel</Button>
          </div>
        </Box>
      </div>
    );
  }
}
