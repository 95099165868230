import axios from "axios";
import "../SiteConstants/SiteConst";

export  async function GetDataFromService(sitepath, methods, data1 = null) {
  var responds = null;
  var errorMsg = "";
  if (methods === "POST") {
    //   console.log(data1);
    await axios
      .post(global.config.ServicePath + sitepath, JSON.stringify(data1))
      .then(async (res) => {
        responds = await res.data;
      })
      .catch((error) => {
        errorMsg = error.toString();
      });
    // console.log('Received Data in POST'+JSON.stringify(responds));
    const values = {
      Error: errorMsg,
      Data: responds,
    };
    return values;
  } else if (methods === "GET") {
    //  console.log(data1);
    await axios
      .get(global.config.ServicePath + sitepath)
      .then(async (res) => {
        responds = await res.data;
      })
      .catch((error) => {
        errorMsg = error.toString();
      });
    //  console.log('Received Data in GET'+JSON.stringify(responds));
    const values = {
      Error: errorMsg,
      Data: responds,
    };
    return values;
  } else if (methods === "DELETE") {
    console.log(data1);
    await axios
      .delete(global.config.ServicePath + sitepath, JSON.stringify(data1))
      .then(async (res) => {
        responds = await res.data;
      })
      .catch((error) => {
        errorMsg = error.toString();
      });
    // console.log('Received Data in Delete'+JSON.stringify(responds));
    const values = {
      Error: errorMsg,
      Data: responds,
    };
    return values;
  } else if (methods === "PUT") {
    console.log(data1);
    await axios
      .put(global.config.ServicePath + sitepath, JSON.stringify(data1))
      .then(async (res) => {
        responds = await res.data;
      })
      .catch((error) => {
        errorMsg = error.toString();
      });
    //  console.log('Received Data in Put'+JSON.stringify(responds));
    const values = {
      Error: errorMsg,
      Data: responds,
    };
    return values;
  } else if (methods === "CREATE") {
    //   console.log(data1);
    await axios
      .create(global.config.ServicePath + sitepath, JSON.stringify(data1))
      .then(async (res) => {
        responds = await res.data;
      })
      .catch((error) => {
        errorMsg = error.toString();
      });
    //   console.log('Received Data in Create'+JSON.stringify(responds));
    const values = {
      Error: errorMsg,
      Data: responds,
    };
    return values;
  } else if (methods === "PATCH") {
    //  console.log(data1);
    await axios
      .patch(global.config.ServicePath + sitepath, JSON.stringify(data1))
      .then(async (res) => {
        responds = await res.data;
      })
      .catch((error) => {
        errorMsg = error.toString();
      });
    //  console.log('Received Data in Patch'+JSON.stringify(responds));
    const values = {
      Error: errorMsg,
      Data: responds,
    };
    return values;
  } else {
    const values = {
      Error: "Invalid Methods",
      Data: null,
    };
    return values;
  }
}
