
import { createSlice } from '@reduxjs/toolkit'

export const calcSlice = createSlice({
  name: 'calc',
  initialState: {
    customer:'',
    customerType:'',
    preparedBy:'',
    product:'',
    length_feet:0,
    length_inch:0,
    width_feet:0,
    width_inch:0,
    serge_option:'',
    rugpad:'',
    productList:[]
  },
  reducers: {

    setvars: (state,action) => {

        state.customer=action.payload.customer;
        state.customerType=action.payload.customerType;
        state.preparedBy=action.payload.preparedBy;
        state.product=action.payload.product;
        state.length_feet=action.payload.length_feet;
        state.length_inch=action.payload.length_inch;
        state.width_feet=action.payload.width_feet;
        state.width_inch=action.payload.width_inch;
        state.serge_option=action.payload.width_inch;
        state.rugpad=action.payload.rugpad;
    },
    setSingleVar: (state,action) => {
      
        state[action.payload.name]=action.payload.value;
    }
    
  },
})

export const { setvars,setSingleVar } = calcSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCalc = (state) => state.calc

export default calcSlice.reducer

