import React, { Component } from "react";
import InputLabel from "@mui/material/InputLabel";
import AutoComplete from "@mui/material/Autocomplete";
import "./Calculator.css";
import TextField from "@mui/material/TextField";
import CustomerPopup from "../BasicComponents/Popups/CustomerPopup";
import {
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
} from "@mui/material";
import { GetDataFromService } from "../SiteModules/Connectivity";
import { RiUserAddFill } from "react-icons/ri";
import AlertDialog from "./AlertDialogue";
import PreviewSection from "./PreviewSection";
export class Calculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: null,
      customerType: "",
      prepaedBy: "",
      product: {},
      widthFeet: 0,
      widthInch: 0,
      lengthFeet: 0,
      lengthInch: 0,
      sergeOption: {},
      rugpad: {},
      customerList: [],
      productList: [],
      sergeList: [],
      RugpadList: [],
      openCustPopup: false,
      infoMessage: "",
      InfoTitle: "Error",
      Opendialog: false,
      result: 0,
      result1:0,
      enablingtext: true,
      productInfo:[]
    };
    this.setWebState = this.setWebState.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }
  async componentDidMount() {
    await this.getProductList();
    await this.SergeOptions();
    await this.Rugpads();
    await this.Customers();
  }

  async getProductList() {
    const tempData1 = await GetDataFromService("/Products", "GET");
    this.setWebState("productList", await tempData1.Data);
  }

  async SergeOptions() {
    const tempData2 = await GetDataFromService("/SergeOptions", "GET");
    this.setWebState("sergeList", await tempData2.Data);
  }
 async GetProductinfo(product) {
    const tempData0 = await GetDataFromService("/GetProductInfo", "POST",product);
    this.setWebState("productInfo", await tempData0.Data);
  }
  async Rugpads() {
    const tempData3 = await GetDataFromService("/Rugpads", "GET");
    this.setWebState("RugpadList", await tempData3.Data);
  }
  async Customers() {
    const tempData4 = await GetDataFromService("/CustomerList", "GET");
    this.setWebState("customerList", await tempData4.Data);
  }
  async setWebState(varName, varValue) {
    await this.setState({
      //...this.state,
      [varName]: varValue,
    });
    //console.log(varValue);
    //console.log(this.state);
  }
  handlePopupOpen = () => {
    this.setWebState("openCustPopup", true);
  };
  handlePopupClose = () => {
    this.setWebState("openCustPopup", false);
    this.Customers();
  };
  calculate = async () => {
    //console.log(this.state);
    if (this.state.customer === null) {
      this.setWebState("infoMessage", "Select a Valid Customer");
      this.setWebState("Opendialog", true);
    } else if (this.state.customerType === "") {
      this.setWebState("infoMessage", "Select a Valid Customer Type");
      this.setWebState("Opendialog", true);
    } else if (this.state.prepaedBy === "") {
      this.setWebState("infoMessage", "Select a Valid Prepared By");
      this.setWebState("Opendialog", true);
    } else if (this.state.product === null) {
      this.setWebState("infoMessage", "Select a Valid Prepared By");
      this.setWebState("Opendialog", true);
    } else if (this.state.lengthFeet === "") {
      this.setWebState("infoMessage", "Select a Valid Prepared By");
      this.setWebState("Opendialog", true);
    } else if (this.state.lengthInch === "") {
      this.setWebState("infoMessage", "Select a Valid Prepared By");
      this.setWebState("Opendialog", true);
    } else if (this.state.widthFeet === "") {
      this.setWebState("infoMessage", "Select a Valid Prepared By");
      this.setWebState("Opendialog", true);
    } else if (this.state.widthInch === "") {
      this.setWebState("infoMessage", "Select a Valid Prepared By");
      this.setWebState("Opendialog", true);
    } else if (this.state.sergeOption === null) {
      this.setWebState("infoMessage", "Select a Valid Customer");
      this.setWebState("Opendialog", true);
    } else if (this.state.rugpad === null) {
      this.setWebState("infoMessage", "Select a Valid Customer Type");
      this.setWebState("Opendialog", true);
    } else {
      const CalcData = {
        customer: this.state.customer,
        customerType: this.state.customerType,
        prepaedBy: this.state.prepaedBy,
        product: this.state.product,
        widthFeet: this.state.widthFeet,
        widthInch: this.state.widthInch,
        lengthFeet: this.state.lengthFeet,
        lengthInch: this.state.lengthInch,
        sergeOption: this.state.sergeOption,
        rugpad: this.state.rugpad,
        logId:this.props.logid
      };
      const tempData6 = await GetDataFromService(
        "/Calculate",
        "POST",
        CalcData
      );
      this.setWebState("result", await tempData6.Data["TotalCost"]);
      this.setWebState("result1", await tempData6.Data["TotWithoutRug"]);

    }
  };
  Validate = (fieldname, values) => {
    if (this.state.product !== null) {
      const width =
        parseInt(fieldname === "widthFeet" ? values : this.state.widthFeet) *
          12 +
        parseInt(fieldname === "widthInch" ? values : this.state.widthInch);
      const realwidth =
        this.state.product["WidthInFeet"] * 12 +
        this.state.product["WidthFractionInInch"];
      if (realwidth < width) {
        this.setWebState(
          "infoMessage",
          `Maximum Length available is ${this.state.product["WidthInFeet"]} feet ${this.state.product["WidthFractionInInch"]} Inch`
        );
        this.setWebState("Opendialog", true);
        this.setWebState([fieldname], 0);
      } else {
        if (fieldname === "widthInch" && values > 11) {
          this.setWebState("infoMessage", `Inch Cannot be More than 11`);
          this.setWebState("Opendialog", true);
        } else {
          this.setWebState([fieldname], values);
        }
      }
    }
  };
  render() {
    return (
      <div className="root-container">
        <h2>Rugs Price Calculator</h2>
        <AlertDialog
          buttonText={"Ok"}
          dialogTitle={this.state.InfoTitle}
          dialogContent={this.state.infoMessage}
          open={this.state.Opendialog}
          parentClose={() => this.setWebState("Opendialog", false)}
        />
        <div className="group-container">
        <div className="item-container">
          <div className="itemProps">
            <InputLabel>Customer</InputLabel>
            <AutoComplete
              size="small"
              disablePortal
              options={this.state.customerList}
              isOptionEqualToValue={(option, value) => option.ID === value.ID}
              getOptionLabel={(Opt) => Opt.CusName}
              onChange={(e, n) => this.setWebState("customer", n)}
              sx={{ width: 300 }}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.ID}>
                  {option.CusName}
                </Box>
              )}
              renderInput={(params) => (
                <div className="customerDiv">
                  <TextField size="small" {...params} />
                  <RiUserAddFill onClick={this.handlePopupOpen} />
                </div>
              )}
            />
          </div>
          <div className="itemProps">
            <InputLabel>Customer Type</InputLabel>
            <div className="radioItems">
              <RadioGroup
                row
                className="radio"
                onChange={(e, v) => this.setWebState("customerType", v)}
              >
                <FormControlLabel
                  value="Dealer"
                  control={<Radio />}
                  label="Dealer"
                />
                <FormControlLabel
                  value="Designer"
                  control={<Radio />}
                  label="Designer"
                />
              </RadioGroup>
            </div>
          </div>
          <div className="itemProps">
            <InputLabel>Prepared By</InputLabel>

            <TextField
              size="small"
              sx={{ width: 300 }}
              onChange={(e) => this.setWebState("prepaedBy", e.target.value)}
            />
          </div>
          <div className="itemProps">
            <InputLabel>Product</InputLabel>
            <AutoComplete
              size="small"
              disablePortal
              id="combo-box-demo"
              options={this.state.productList}
              sx={{ width: 300 }}
              isOptionEqualToValue={(option, value) => option.ID === value.ID}
              getOptionLabel={(option) => option.ProductName}
              onChange={async (e, n) => {
                await this.setWebState("product", n);
                if (this.state.product !== null) {
                  await this.setWebState("enablingtext", false);
                  this.GetProductinfo(n);
                } else {
                  await this.setWebState("enablingtext", true);
                  this.setWebState('productInfo',[]);
                }
                await this.setWebState("widthFeet", 0);
                await this.setWebState("widthInch", 0);
                await this.setWebState("lengthFeet", 0);
                await this.setWebState("lengthInch", 0);
                this.setWebState("result", 0);
                this.setWebState("result1", 0);
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </div>
          <div className="itemProps">
            <InputLabel>Width</InputLabel>
            <div>
              <TextField
                size="small"
                label=""
                value={this.state.widthFeet.toString()}
                name="width_feet"
                helperText={""}
                disabled={Boolean(this.state.enablingtext)}
                sx={{ width: 150 }}
                onChange={(e) => {
                  this.Validate("widthFeet", e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <sub>Feet</sub>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                label=""
                name="width_Inch"
                helperText={""}
                value={this.state.widthInch.toString()}
                disabled={Boolean(this.state.enablingtext)}
                sx={{ width: 150 }}
                onChange={(e) => this.Validate("widthInch", e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <sub>Inch</sub>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="itemProps">
            <InputLabel>Length</InputLabel>
            <div>
              <TextField
                onChange={(e) => this.setWebState("lengthFeet", e.target.value)}
                size="small"
                label=""
                disabled={Boolean(this.state.enablingtext)}
                name="length_feet"
                value={this.state.lengthFeet.toString()}
                helperText={""}
                sx={{ width: 150 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <sub>Feet</sub>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                label=""
                name="length_Inch"
                disabled={Boolean(this.state.enablingtext)}
                helperText={""}
                value={this.state.lengthInch.toString()}
                sx={{ width: 150 }}
                onChange={(e) => {
                  if ( e.target.value > 11) {
                    this.setWebState("infoMessage", `Inch Cannot be More than 11`);
                    this.setWebState("Opendialog", true);
                  } else {
                    this.setWebState("lengthInch", e.target.value)
                  }
                  
                }
              }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <sub>Inch</sub>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className="itemProps">
            <InputLabel>Serge Option</InputLabel>
            <AutoComplete
              size="small"
              disablePortal
              id="combo-box-demo"
              options={this.state.sergeList}
              sx={{ width: 300 }}
              isOptionEqualToValue={(option, value) => option.ID === value.ID}
              getOptionLabel={(option) => option.SergeType}
              onChange={(e, n) => this.setWebState("sergeOption", n)}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </div>
          <div className="itemProps">
            <InputLabel>Rugpad</InputLabel>
            <AutoComplete
              size="small"
              disablePortal
              id="combo-box-demo"
              options={this.state.RugpadList}
              sx={{ width: 300 }}
              isOptionEqualToValue={(option, value) => option.ID === value.ID}
              getOptionLabel={(option) => option.RugpadName}
              onChange={(e, n) => this.setWebState("rugpad", n)}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </div>
          <div className="itemProps">
            <InputLabel >Total without Rugpad</InputLabel>
           
              <div className="price-Label-subtotal">{Number(this.state.result1).toFixed(2) + "$"}</div>
            
          </div>
          <div className="itemProps">
            <InputLabel >Total</InputLabel>
            
              <div className="price-Label">{Number(this.state.result).toFixed(2) + "$"}</div>
            
          </div>
          <div className="itemPropsButton">
            <Button variant="contained" onClick={() => this.calculate()}>
              Calculate
            </Button>
          </div>
          <CustomerPopup
            open={this.state.openCustPopup}
            handlePopupClose={this.handlePopupClose}
          />
        </div>
        <div className="item-container">
          <PreviewSection productInfo={this.state.productInfo}/>
        </div>
        </div>
      </div>
    );
  }
}

export default Calculator;
