import React from "react";
import PrimaryAppBar from "../BasicComponents/ApplicationBar";

import Home from "./../Components/Home";

export default function HomePage() {
  return (
    <div>
      <PrimaryAppBar />
      <Home />
    </div>
  );
}
