import React, { useState, useEffect } from "react";
import ImageHoverZoom from "./ImageHoverZoom";
import "./PreviewSection.css";

const PreviewSection = ({ productInfo }) => {
  const [imgUrl, setUrl] = useState("");
  const [active, setActive] = useState(1);

  useEffect(() => {
    if (productInfo.length === 0) {
      setUrl("");
      setActive(1);
    } else {
      setUrl(productInfo[0].PImage);
    }
  }, [productInfo]);
  return (
    <div>
      <div>
        <ImageHoverZoom imagePath={imgUrl} />
      </div>
      <div className="container">
        {productInfo.map((value) => {
          //  console.log("id=" + value.id);
          return (
            <div key={value.id}>
              <div className="itemContainer">
                <img
                  className={value.id !== active ? "item" : "selectedItem"}
                  src={value.Thumbnail}
                  width="40px"
                  alt=""
                  onClick={() => {
                    setUrl(value.PImage);
                    setActive(value.id);
                  }}
                />
                <label className="labelColor">{value.Color}</label>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default PreviewSection;
