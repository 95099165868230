import React from 'react'
import { useSelector } from 'react-redux';
import PrimaryAppBar from '../BasicComponents/ApplicationBar'
import Calculator from '../Components/Calculator'

export default function CalculatorPage() {
  const logid = useSelector((state) => state.login.loginid);
  return (
    <>
    <PrimaryAppBar/>
    <Calculator logid={logid}/>
    </>
  )
}


