import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import CalculatorPage from "../Pages/CalculatorPage";
import HomePage from "../Pages/HomePage";
import LoginPage from "../Pages/LoginPage";
import { useSelector } from "react-redux";

export function SiteRouter() {
  const logState = useSelector((state) => state.login.loginstate);
  return (
    <Router>
      <Routes>
        {logState ? (
          <>
            <Route exact path="/" element={<HomePage />}></Route>
            <Route exact path="/Calc" element={<CalculatorPage />}></Route>
            <Route exact path="/*" element={<h1>Page not Found</h1>}></Route>
          </>
        ) : (
          <>
            <Route exact path="/" element={<LoginPage />}></Route>
            <Route exact path="/*" element={<h1>Page not Found</h1>}></Route>
          </>
        )}
      </Routes>
    </Router>
  );
}

export default SiteRouter;
