import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import counterReducer from "../StateManager/counter";
import loginReducer from "../StateManager/loginslice";
import calcReducer from "../StateManager/CalcSlice";
const rootreducer = combineReducers({
  counter: counterReducer,
  login: loginReducer,
  calc: calcReducer,
});
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootreducer);
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
export const persistor = persistStore(store);
store.subscribe(() => console.log(store.getState().calc));
